<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!warehouseUnlockInformation">
      Agregar desbloqueo de almacen
    </h1>

    <div v-if="!warehouseUnlockInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado. </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field label="Código (Requerido)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Almacen1"
          expanded
          icon="file-outline"
          v-model="newCientUnlock.CODIGO"
          type="password"
          password-reveal
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        class="required-input"
        label-position="inside"
        label="Almacen (Requerido)"
        expanded
      >
        <b-autocomplete
          :data="autoCompleteWarehouses(warehouseName)"
          field="NOMBRE_ALM"
          v-model="warehouseName"
          placeholder="Almacen"
          icon="earth"
          open-on-focus
          clearable
          @select="
            (option) => {
              newCientUnlock.ALMACEN = option._id;
            }
          "
          check-infinite-scroll
        >
          <template #empty>No se encontraron almacenes</template>
        </b-autocomplete>
      </b-field>
    </b-field>

    <div>
      <b-field grouped class="special-margin required-input">
        <b-field expanded label-position="inside">
          <template slot="label">
            Fecha de caducidad (Requerido)
            <b-tooltip
              multilined
              label="Feha a la que termina el desbloqueo del almacen"
            >
              <b-icon
                size="is-small"
                icon="help-circle-outline"
                custom-class="input-help-icon"
              ></b-icon>
            </b-tooltip>
          </template>
          <b-datetimepicker
            :datetime-formatter="
              (date) => moment(date).format('DD/MM/YYYY HH:mm')
            "
            :datepicker="datepicker"
            v-model="newCientUnlock.FECHA_FIN"
            placeholder="Ejemplo 20/10/2018"
            trap-focus
            icon="calendar-today"
            horizontal-time-picker
            required
          >
          </b-datetimepicker>
        </b-field>
      </b-field>
    </div>

    <div class="add-global-controls" v-if="!warehouseUnlockInformation">
      <b-button type="is-success" @click="saveWarehouseUnlock()"
        >Guardar debloqueo de almacen</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import { fillObjectForm } from "../../../utils/component-fns";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "AddWarehouseUnlockModal",
  props: ["warehouseUnlockInformation"],
  directives: { cleave },
  components: {},
  data() {
    return {
      newCientUnlock: {
        CODIGO: "",
        CANTIDAD: 0,
        FECHA_FIN: new Date(),
        ALMACEN: undefined,
      },
      warehouseName: "",
      moment: moment,
      datepicker: {
        "month-names": [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        "day-names": ["D", "L", "M", "M", "J", "V", "S"],
        "show-week-number": true,
        locale: "es-MX",
      },
      masks: Masks,
      errors: {
        CODIGO: false,
        CANTIDAD: false,
        FECHA_FIN: false,
        ALMACEN: false,
      },
    };
  },
  async mounted() {
    // Fill the article information in form fields
    if (this.warehouseUnlockInformation) {
      // Fill the form with received information
      fillObjectForm(this.warehouseUnlockInformation, this.newCientUnlock);
      this.newCientUnlock._id = this.warehouseUnlockInformation._id;

      // Fill in warehouse information
      for (const singleWarehouse of this.warehouses) {
        if (
          singleWarehouse._id === this.warehouseUnlockInformation.ALMACEN._id
        ) {
          this.newCientUnlock.ALMACEN = singleWarehouse._id;
          this.warehouseName = singleWarehouse.NOMBRE_ALM;
          break;
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async saveWarehouseUnlock() {
      try {
        this.errors = validateFields(this.newCientUnlock, this.errors);
        if (checkValidationErrors(this.errors)) {
          let response = await this.$store.dispatch(
            "SAVEWAREHOUSEUNLOCK",
            this.newCientUnlock
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el debloqueo de almacen",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar el desbloqueo de almacen en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el desbloqueo de almacen en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el desbloqueo de almacen en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    autoCompleteWarehouses(value) {
      if (!this.warehouses) {
        return [];
      }
      return this.warehouses.filter((singleWarehouse) => {
        return (
          singleWarehouse.NOMBRE_ALM.toLowerCase().indexOf(
            value.toLowerCase()
          ) >= 0 ||
          singleWarehouse.CLAVE_ALMACEN.toLowerCase().indexOf(
            value.toLowerCase()
          ) >= 0
        );
      });
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
  },
};
</script>

<style scoped>
@import "../../Purchases/style/AddPurchaseOrder.css";
@import "../../Configuration/style/Configuration.css";
@import "../../Global/style/Global.css";
</style>
