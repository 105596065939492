import { render, staticRenderFns } from "./VirtualWarehouse.vue?vue&type=template&id=783fb90d&scoped=true&"
import script from "./VirtualWarehouse.vue?vue&type=script&lang=js&"
export * from "./VirtualWarehouse.vue?vue&type=script&lang=js&"
import style0 from "./VirtualWarehouse.vue?vue&type=style&index=0&id=783fb90d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "783fb90d",
  null
  
)

export default component.exports