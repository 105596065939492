<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Almacenes </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Almacenes" icon="warehouse">
        <WarehousesLists></WarehousesLists>
      </b-tab-item>
      <b-tab-item label="Almacenes de defectuosos" icon="image-broken-variant">
        <FaultyWarehouse></FaultyWarehouse>
      </b-tab-item>
      <b-tab-item label="Almacenes virtuales" icon="semantic-web">
        <VirtualWarehouse></VirtualWarehouse>
      </b-tab-item>
      <b-tab-item label="Desbloqueo de almacenes" icon="lock">
        <WarehousesUnlocksList></WarehousesUnlocksList>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import WarehousesLists from "../components/WarehousesList";
import FaultyWarehouse from "../components/FaultyWarehouse";
import VirtualWarehouse from "../components/VirtualWarehouse";
import WarehousesUnlocksList from "../../Sales/components/WarehousesUnlocksList";

// @ is an alias to /src
export default {
  name: "WarehouseComponentsContainer",
  components: {
    WarehousesLists,
    VirtualWarehouse,
    FaultyWarehouse,
    WarehousesUnlocksList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "warehouses");
    this.$store.dispatch("GETWAREHOUSESUNLOCKS");
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
