<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control" id="actions-options">
        <b-button type="is-primary" @click="openAddWarehose()"
          >Agregar almacén</b-button
        >
      </div>
      <!--
          <div class="control">
              <b-button type="is-primary" @click="startImport()">Importar almacenes</b-button>
          </div>
          -->
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns" id="current-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="table-header">
      Cantidad de Almacenes: <b>{{ warehouses.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="warehouses"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      ref="WarehousesTable"
      :checked-rows.sync="checkedWarehouses"
      paginated
      :current-page="currentPage"
      :per-page="perPage"
      :opened-detailed="defaultOpenedDetails"
      :selected.sync="selected"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Información de ${row.NOMBRE_ALM}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      detail-key="_id"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="asc"
      checkbox-position="left"
      default-sort="CLAVE_ALMACEN"
    >
      <template #empty>
        <div class="has-text-centered">No hay almacenes</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          :visible="column.display"
          v-bind="column"
          :key="column.field"
          :label="column.label"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>

          <span>{{ props.row[column.field] }}</span>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="content">
            <AddWarehouseModal :warehouseInformation="props.row" />
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import AddWarehouseContact from "./AddWarehouseContact";
import AddWarehouseModal from "./AddWarehouseModal";
import moment from "moment";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import { EventBus } from "../../../event-bus";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "WarehousesList",
  components: {
    AddWarehouseModal,
  },
  directives: { cleave },
  data() {
    //warehouses
    return {
      perPage: 50,
      currentPage: 1,
      checkedWarehouses: [],
      selected: {},
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_ALMACEN",
          label: "Clave de almacén",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_ALM",
          label: "Nombre",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "STATUS_FOR",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIP_BREV",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DOMICIL_ALM",
          label: "Dirección",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CP_ALM",
          label: "Código postal",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      warehouseRequiredErrors: {
        CLAVE_ALMACEN: false,
        NOMBRE_ALM: false,
        DESCRIP_BREV: false,
        UBICACION: false,
        CP_ALM: false,
      },
      shippingActive: false,
      shippingTaxActive: false,
      insuranceActive: false,
      insuranceTaxActive: false,
      movementCostActive: false,
      masks: Masks,
    };
  },
  async created() {
    moment.locale("es");
    await this.$store.dispatch("GETWAREHOUSES", {
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 15
      ),
      endDate: new Date(),
    });
  },
  mounted() {
    /**
     * Catch new phones values and call the store to save changes
     */
    EventBus.$on("sendWarehousePhonesId", (phones, warehouseId) => {
      let finalWarehouses = this.warehouses.map((singleWarehouse) => {
        if (singleWarehouse._id === warehouseId) {
          // Saves new values in the store
          singleWarehouse.TELEFONOS = phones;
          return singleWarehouse;
        }
        return singleWarehouse;
      });
      this.warehouses = finalWarehouses;
    });
  },
  beforeDestroy() {
    EventBus.$off();
  },
  methods: {
    /**
     * @desc Method to delete checked warehouses
     */
    confirmDeleteWarehouses() {
      if (this.checkedWarehouses.length > 0) {
        this.$buefy.dialog.confirm({
          title: "Borrar almacenes",
          message:
            "¿Estas seguro que deseas <b>borrar</b> los almacenes seleccinados? Esta acción no puede ser cambiada.",
          confirmText: "Borrar almacenes",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () =>
            this.$store
              .dispatch("DELETEWAREHOUSES", this.checkedWarehouses)
              .then((response) => {
                if (response == "Success") {
                  this.$buefy.dialog.confirm({
                    title: "Éxito",
                    message:
                      "Se borraron exitosamente los almacenes seleccionadas",
                    confirmText: "Entendido",
                    type: "is-success",
                    hasIcon: true,
                    cancelText: "cancelar",
                    canCancel: false,
                  });
                } else {
                  this.$buefy.dialog.confirm({
                    title: "Error",
                    message:
                      "Hubo un error al borrar los almacenes en la base de datos",
                    confirmText: "Entendido",
                    type: "is-danger",
                    hasIcon: true,
                    cancelText: "cancelar",
                    canCancel: false,
                  });
                }
              }),
        });
      } else {
        this.$buefy.dialog.confirm({
          title: "Atención",
          message: "No hay almacenes seleccionados",
          confirmText: "Entendido",
          hasIcon: true,
          type: "is-danger",
          onConfirm: () => {},
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     * @desc Display add new Warehouse modal
     */
    openAddWarehose() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddWarehouseModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     * @desc Display add new Warehouse modal
     */
    openAddWarehousePhones(warehouseData) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddWarehouseContact,
        props: {
          warehousePhones: warehouseData.TELEFONOS,
          warehouseId: warehouseData._id,
          warehousesList: true,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    confirmExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación de almacenes",
        message: `¿Desea confirmar la exportación de los almacenes a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: () => this.exportData(),
      });
    },
    startImport() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddWarehouseModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    exportData() {
      let payload = {
        data: this.data,
        name: "warehouses-" + moment().format("l").toString(),
      };
      this.$store.dispatch("EXPORTCSV", payload);
    },
  },
  watch: {
    /**
     * @desc Select default opened details, to open a specific warehouse
     */
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES_ALL.map((singleWarehouse) => {
        let singleWarehouseWithProps = { ...singleWarehouse };
        if (singleWarehouseWithProps.STATUS === "AC") {
          singleWarehouseWithProps.STATUS_FOR = "Activo";
        }
        if (singleWarehouseWithProps.STATUS === "PE") {
          singleWarehouseWithProps.STATUS_FOR = "Desactivado";
        }
        return singleWarehouseWithProps;
      }).filter((singleWarehouse) => {
        if (!singleWarehouse.TIPO || singleWarehouse.TIPO === "AL") {
          return true;
        }
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
