<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Teléfonos de almacén</h1>

    <div class="control add-global-controls" v-if="!warehousesList">
      <b-button type="is-primary" @click="pushPhoneNumber()"
        >Agregar teléfono extra</b-button
      >
    </div>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <div v-for="(item, index) in phones" :key="index">
      <h3 class="form-division">Teléfono {{ index + 1 }}</h3>

      <b-field grouped class="special-margin">
        <b-field label="Nombre (Requerido)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: Pedro Rodriguez"
            icon="domain"
            v-model="item.NOMBRE"
            required
          ></b-input>
        </b-field>
        <b-field label="Teléfono (Requerido)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: 0000000000"
            icon="bank-outline"
            v-model="item.TELEFONO"
            v-cleave="masks.customPhone"
            required
          ></b-input>
        </b-field>
      </b-field>
    </div>

    <div class="add-global-controls">
      <b-button type="is-success" @click="sendWarehousePhones()"
        >Guardar teléfonos de almacén</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import { EventBus } from "../../../event-bus";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "AddWarehouseContact",
  /**
   * warehousePhones = phones already saved
   * warehouseId = id used in warehouse list details
   */
  props: ["warehousePhones", "warehouseId", "warehousesList"],
  components: {},
  directives: { cleave },
  data() {
    return {
      currentPhones: 0,
      phones: [],
      masks: Masks,
    };
  },
  mounted() {
    if (this.warehousePhones.length === 0) {
      let initialPhone = {
        NOMBRE: "",
        TELEFONO: "",
      };
      this.phones.push(initialPhone);
      this.currentPhones++;
    } else {
      this.phones = this.warehousePhones;
      this.currentPhones = this.warehousePhones.length;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    pushPhoneNumber() {
      let tempPhone = {
        NOMBRE: "",
        TELEFONO: "",
      };
      this.phones.push(tempPhone);
      this.currentPhones++;
    },
    /**
     *
     * @desc checks the array of contacts and returns the valid ones
     * @returns [] an array with the valid phone numbers
     */
    validateWarehouseContactInformation() {
      let validContacts = [];
      for (const singleContact of this.phones) {
        if (singleContact.NOMBRE && singleContact.TELEFONO) {
          validContacts.push(singleContact);
        }
      }
      return validContacts;
    },
    /**
     *
     * @desc sends the phones back to the add warehouse component
     */
    sendWarehousePhones() {
      const validPhones = this.validateWarehouseContactInformation();
      if (this.warehouseId) {
        EventBus.$emit("sendWarehousePhonesId", validPhones, this.warehouseId);
      } else {
        EventBus.$emit("sendWarehousePhones", validPhones);
      }

      this.$buefy.dialog.confirm({
        title: "Éxito",
        message: "Se guardó exitosamente la información de contacto",
        confirmText: "Entendido",
        type: "is-success",
        hasIcon: true,
        onConfirm: () => {
          this.closeModal();
        },
        cancelText: "cancelar",
        canCancel: false,
      });
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
